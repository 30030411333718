import Flickity from 'flickity'
import jQueryBridget from 'jquery-bridget'

// See https://github.com/desandro/jquery-bridget for options
jQueryBridget('flickity', Flickity, jQuery)

export const standardParams = {
  cellAlign: 'left',
  percentPosition: false,
  wrapAround: false,
  contain: true,
  freeScroll: false,
  pageDots: false,
  prevNextButtons: false,
  autoPlay: false,
  on: {
    // Prevent clicking of links when swiping
    dragStart () {
      jQuery(this.element).children().css('pointer-events', 'none')
      document.ontouchmove = e => e.preventDefault()
    },
    dragEnd () {
      jQuery(this.element).children().css('pointer-events', 'auto')
      document.ontouchmove = () => true
    }
  }
}

jQuery(document).ready(() => {
  const $sliders = jQuery('.l-overview__cards, .l-experiences__cards')
  if ($sliders && $sliders.length) {
    const sliders = $sliders.flickity(Object.assign({}, standardParams, {
      groupCells: false,
      prevNextButtons: false,
      watchCSS: true
    }))

    sliders.each((i, slider) => {
      // Store variables
      const $slider = jQuery(slider)
      const flkty = $slider.data('flickity')
      const $prevButton = $slider.parent().find('.nav-button--previous')
      const $nextButton = $slider.parent().find('.nav-button--next')

      $prevButton.click(() => {
        // Go to the previous slide
        jQuery(slider).flickity('previous')

        // Store variables
        const selectedIndex = flkty.selectedIndex

        // Since we're never on the last slide when we click previous
        // Remove the disabled class on next
        $nextButton.removeClass('nav-button--disabled')

        // If we're on the first slide
        if (selectedIndex === 0) {
          $prevButton.addClass('nav-button--disabled')
        }
      })

      $nextButton.click(() => {
        // Go to the next slide
        jQuery(slider).flickity('next')

        // There's no next
        if (jQuery(flkty.cells[flkty.cells.length - 1].element).hasClass('is-selected')) {
          $nextButton.addClass('nav-button--disabled')
        }

        // Since where never on the first slide when we click next
        // Remove the disabled class on previous
        $prevButton.removeClass('nav-button--disabled')
      })
    })
  }

  const $menuSliders = jQuery('.hash-submenu-inline')
  if ($menuSliders && $menuSliders.length) {
    $menuSliders.flickity(Object.assign({}, standardParams, {
      groupCells: false,
      cellAlign: 'center'
    }))
  }

  const $mobileSliders = jQuery('.small-steps, .l-experiences__stories')
  if ($mobileSliders && $mobileSliders.length) {
    $mobileSliders.flickity(Object.assign({}, standardParams, {
      groupCells: false,
      watchCSS: true
    }))
  }

  const $gallerySliders = jQuery('.l-gallery__images')
  if ($gallerySliders && $gallerySliders.length) {
    $gallerySliders.flickity(Object.assign({}, standardParams, {
      groupCells: false,
      watchCSS: false,
      freeScroll: true,
      prevNextButtons: true
    }))
  }
})
