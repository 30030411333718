import Masonry from 'masonry-layout'
import docReady from '@/js/helpers/doc-ready'
import { getParameterByName, updateUrlParameter, removeURLParameter } from '@/js/components/url'
import { gsap } from 'gsap'
import Plyr from 'plyr'
import { scrollToY } from '@/js/helpers/scroll'

const axios = require('axios')

// eslint-disable-next-line
let msnry = null
let paged = 1
let questionCategoryId = 'all'

const getQuestionCategory = id => {
  questionCategoryId = id
  const url = updateUrlParameter(window.location.href, 'vraagCategorie', id)
  window.history.replaceState({}, '', url)
  getQuestionsFromPage({
    page: +paged,
    termId: id
  })

  Array.from(document.querySelectorAll('[data-question-category]')).forEach(el => {
    const cat = el.getAttribute('data-question-category')
    el.classList.toggle('button--active', cat === id)
    el.classList.toggle('button--regular', cat !== id)
  })
}

const getExperience = url => {
  axios
    .get(window.ajaxurl, {
      params: {
        url,
        action: 'load_experience'
      }
    })
    .then(res => res.data)
    .then(data => {
      if (data) {
        const modal = document.querySelector('.modal--experience')
        const entry = document.getElementById('experience-modal')
        if (modal && entry) {
          entry.innerHTML = data
          gsap.to(modal, {
            autoAlpha: 1,
            pointerEvents: 'initial'
          })
          gsap.set(document.body, {
            overflow: 'hidden'
          })

          const embedPlayer = document.querySelector('.embed-plyr')
          if (embedPlayer) {
            // eslint-disable-next-line
            const plyrPlayer = new Plyr(embedPlayer, {
              controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
            })
          }
        }
      }
    })
}

const getSingularQuestion = id => {
  axios
    .get(window.ajaxurl, {
      params: {
        id,
        action: 'load_question'
      }
    })
    .then(res => res.data)
    .then(data => {
      if (data) {
        const url = updateUrlParameter(window.location.href, 'questionId', id)
        window.history.replaceState({}, '', url)

        const modal = document.querySelector('.modal--question')
        const entry = document.getElementById('question-modal')
        if (modal && entry) {
          entry.innerHTML = data
          gsap.to(modal, {
            autoAlpha: 1,
            pointerEvents: 'initial'
          })
          gsap.set(document.body, {
            overflow: 'hidden'
          })
        }
      }
    })
}

const getQuestionsFromPage = params => {
  // Nav
  axios
    .get(window.ajaxurl, {
      params: {
        ...params,
        action: 'load_questions_nav'
      }
    })
    .then(res => res.data)
    .then(data => {
      if (data) {
        paged = params.page
        const navEl = document.getElementById('ajax-questions-nav')
        if (navEl) {
          navEl.innerHTML = data
        }
      }
    })

  // Questions
  axios
    .get(window.ajaxurl, {
      params: {
        action: 'load_questions',
        ...params
      }
    })
    .then(res => res.data)
    .then(data => {
      if (data) {
        const questionsEl = document.getElementById('ajax-questions')
        if (questionsEl) {
          questionsEl.innerHTML = data
        }

        const newItems = document.querySelectorAll('[data-masonry-item]:not(.is-in-position)')
        const url = updateUrlParameter(window.location.href, 'page', params.page)
        window.history.replaceState({}, '', url)

        msnry.reloadItems(newItems)
        msnry.layout()

        const targetEl = document.getElementById('questions-overview')
        if (targetEl) {
          const rect = targetEl.getBoundingClientRect()
          const offset = rect.top
          const bodyAmount = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

          scrollToY(offset + bodyAmount - 84, 1000)
        }
      }
    })
}

docReady(() => {
  if (getParameterByName('page')) {
    paged = getParameterByName('page')
  }

  if (getParameterByName('vraagCategorie')) {
    questionCategoryId = getParameterByName('vraagCategorie')
  }

  if (getParameterByName('questionId')) {
    getSingularQuestion(getParameterByName('questionId'))
  }

  if (window.pagedNum) {
    paged = window.pagedNum
  }

  if (paged !== 1 && paged !== '1') {
    getQuestionsFromPage({
      page: +paged,
      termId: questionCategoryId
    })
  }

  const masonryElements = document.querySelector('[data-masonry]')
  if (masonryElements) {
    // eslint-disable-next-line
    msnry = new Masonry(masonryElements, {
      itemSelector: '[data-masonry-item]',
      percentPosition: true
    })

    Array.from(document.querySelectorAll('[data-masonry-item]')).forEach(el => {
      el.classList.add('is-in-position')
    })
  }

  const categorySelect = document.getElementById('category-select')
  if (categorySelect) {
    categorySelect.addEventListener('change', (e) => {
      const curr = e.target
      const value = curr.value

      if (value) {
        window.location.href = value
      }
    })
  }

  const questionCategorySelect = document.getElementById('question-category-select')
  if (questionCategorySelect) {
    questionCategorySelect.addEventListener('change', e => {
      questionCategoryId = e.target.value
      paged = 1
      getQuestionsFromPage({
        page: 1,
        termId: questionCategoryId
      })
    })
  }

  document.addEventListener('click', e => {
    if (e.target.hasAttribute('data-questions-button')) {
      const page = e.target.getAttribute('data-questions-button')
      getQuestionsFromPage({
        page: page,
        termId: questionCategoryId
      })
    }

    if (e.target.hasAttribute('data-question-id')) {
      const questionId = e.target.getAttribute('data-question-id')
      getSingularQuestion(questionId)
    }
  })

  Array.from(document.querySelectorAll('.modal--question__close')).forEach(el => {
    el.addEventListener('click', () => {
      const url = removeURLParameter(window.location.href, 'questionId')
      window.history.replaceState({}, '', url)

      const modal = document.querySelector('.modal--question')
      const entry = document.getElementById('question-modal')
      if (modal && entry) {
        gsap.set(document.body, {
          clearProps: 'overflow'
        })
        gsap.to(modal, {
          opacity: 0,
          pointerEvents: 'initial',
          clearProps: 'all'
        })
      }
    })
  })

  Array.from(document.querySelectorAll('.modal--experience__close')).forEach(el => {
    el.addEventListener('click', () => {
      const modal = document.querySelector('.modal--experience')
      const entry = document.getElementById('experience-modal')
      if (modal && entry) {
        gsap.set(document.body, {
          clearProps: 'overflow'
        })
        gsap.to(modal, {
          opacity: 0,
          pointerEvents: 'initial',
          clearProps: 'all'
        })
      }
    })
  })

  Array.from(document.querySelectorAll('[data-experience]')).forEach(el => {
    el.addEventListener('click', () => {
      const experienceUrl = el.getAttribute('data-experience')
      if (experienceUrl) {
        getExperience(experienceUrl)
      }
    })
  })

  Array.from(document.querySelectorAll('[data-question-category]')).forEach(el => {
    el.addEventListener('click', () => {
      const category = el.getAttribute('data-question-category')
      if (category) {
        paged = 1
        getQuestionCategory(category)
      }
    })
  })
})
