import { scrollToY } from '@/js/helpers/scroll'
import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const links = document.querySelectorAll('a[href*="#"]:not([href="#"])')
  const scrollNextButtons = document.querySelectorAll('[data-scroll-next]')
  const scrollSectionButtons = document.querySelectorAll('[data-scroll-section]')

  if (links.length) {
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', function (e) {
        const target = this.hash
        const targetEl = document.getElementById(target.slice(1))

        if (targetEl) {
          const rect = targetEl.getBoundingClientRect()
          const offset = rect.top
          const bodyAmount = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

          scrollToY(offset + bodyAmount - 84, 1000)
        }
      })
    }
  }

  const wh = window.innerHeight

  Array.from(scrollNextButtons).forEach(el => {
    el.addEventListener('click', () => {
      scrollToY(wh, 1000)
    })
  })

  Array.from(scrollSectionButtons).forEach(el => {
    el.addEventListener('click', () => {
      const ws = window.scrollY
      Array.from(document.querySelectorAll('.l')).some(l => {
        const isNextBlock = l.offsetTop - ws > 0
        if (isNextBlock) {
          scrollToY(l.offsetTop, 1000)
        }

        return isNextBlock
      })
    })
  })
})
