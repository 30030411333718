// Modernizr
import 'root/.modernizrrc'

// Stylesheets
import '@/scss/style'

// Scripts
// import '@/js/components/animsition'
import '@/js/components/animate'
import '@/js/components/navigation'
import '@/js/components/vh'
import '@/js/components/hash'
import '@/js/components/video'
import '@/js/components/qa'
import '@/js/components/side-menu'
import '@/js/components/masonry'
import '@/js/components/sliders'
import '@/js/components/cursor'
