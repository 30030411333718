import throttle from '@/js/helpers/throttle'
import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

class Navigation {
  constructor () {
    this.body = document.body
    this.hamburgers = this.body.querySelectorAll('.nav-hamburger')
    this.wrapper = this.body.querySelector('.wrapper')
    this.menus = this.body.querySelectorAll('.nav')
    this.toggleElements = this.body.querySelectorAll('.navbar-toggle')
    this.slide = this.body.querySelector('.nav-slide')
    this.subItems = this.body.querySelectorAll('.hash-submenu__item a')
    this.active = false
    this.initEvents()
  }

  toggleMenu () {
    this.active = !this.active
    // And toggle the active class
    this.body.classList.toggle('body--slide-active', this.active)
    this.wrapper.classList.toggle('wrapper--slide', this.active)

    Array.from(this.menus).forEach(el => {
      el.classList.toggle('nav--active', this.active)
    })
    Array.from(this.hamburgers).forEach(el => {
      el.classList.toggle('nav-hamburger--is-active', this.active)
    })

    gsap.to(this.slide, {
      autoAlpha: this.active ? 1 : 0,
      ease: 'expo.out',
      duration: 0.4
    })
  }

  headerScroll (ws) {
    const isScrolled = ws > 30

    this.body.classList.toggle('menu-scroll', isScrolled)
    Array.from(this.menus).forEach(el => {
      el.classList.toggle('menu-scroll', isScrolled)
    })
  }

  initEvents () {
    Array.from(this.toggleElements).forEach(el => {
      el.addEventListener('click', this.toggleMenu.bind(this))
    })

    Array.from(this.subItems).forEach(el => {
      el.addEventListener('click', this.toggleMenu.bind(this))
    })

    window.addEventListener('scroll', throttle(() => {
      const ws = window.pageYOffset
      this.headerScroll(ws)
    }, 100))

    this.headerScroll(0)
  }
}

docReady(() => {
  // eslint-disable-next-line
  const nav = new Navigation()
})
