import { scrollToY } from '@/js/helpers/scroll'
import docReady from '@/js/helpers/doc-ready'
import throttle from '@/js/helpers/throttle'

class SideMenu {
  constructor (el) {
    this.el = el
    this.items = document.querySelectorAll('[data-side-menu-item]')

    this.setDots()
    this.initEvents()
  }

  setDots () {
    let dotsStr = ''
    Array.from(this.items).forEach(item => {
      const itemId = item.getAttribute('data-side-menu-item')
      if (itemId) {
        dotsStr += `<button class="side-menu__dot" data-side-menu-dot="${itemId}"></button>`
      }
    })

    if (dotsStr) {
      this.el.insertAdjacentHTML('afterbegin', dotsStr)
    }
  }

  goToDot (e) {
    const target = e.currentTarget
    if (target) {
      const dotId = target.getAttribute('data-side-menu-dot')
      if (dotId) {
        const item = document.querySelector(`[data-side-menu-item="${dotId}"]`)
        if (item) {
          const rect = item.getBoundingClientRect()
          const offset = rect.top
          const bodyAmount = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
          scrollToY(offset + bodyAmount, 1000)
        }
      }
    }
  }

  checkSections () {
    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop

    Array.from(this.items).forEach(el => {
      const itemId = el.getAttribute('data-side-menu-item')

      if (!itemId) {
        return
      }

      const active = el.offsetTop <= scrollPos + 10
      const links = document.querySelectorAll(`[data-side-menu-dot=${itemId}]`)

      if (active) {
        Array.from(document.querySelectorAll('.side-menu__dot--active')).forEach(item => {
          item.classList.remove('side-menu__dot--active')
        })
        Array.from(links).forEach(item => {
          item.classList.add('side-menu__dot--active')
        })
      }
    })
  }

  initEvents () {
    const scrollHandler = () => this.checkSections()
    window.addEventListener('scroll', throttle(scrollHandler, 100))
    Array.from(document.querySelectorAll('.side-menu__dot')).forEach(dot => {
      const clickHandler = e => this.goToDot(e)
      dot.addEventListener('click', clickHandler)
    })
  }
}

docReady(() => {
  const sideMenuEl = document.querySelector('[data-side-menu]')
  if (sideMenuEl) {
    // eslint-disable-next-line
    const sideMenu = new SideMenu(sideMenuEl)
  }
})
