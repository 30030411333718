import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

class AccordionItem {
  constructor (el) {
    this.el = el
    this.header = this.el.querySelector('.extra-info__header')
    this.body = this.el.querySelector('.extra-info__body')
    this.content = this.el.querySelector('.extra-info__content')

    this.active = false

    this.initEvents()
  }

  initEvents () {
    if (this.body) {
      gsap.set(this.body, {
        height: 0
      })
      gsap.set(this.content, {
        y: 50,
        opacity: 0
      })
    }

    if (this.header) {
      this.header.addEventListener('click', () => {
        this.active = !this.active
        this.el.classList.toggle('extra-info--active')

        gsap.to(this.body, {
          duration: this.active ? 1 : 0.4,
          ease: 'expo.out',
          height: this.active ? this.content.offsetHeight : 0
        })
        gsap.to(this.content, {
          duration: this.active ? 0.6 : 0.4,
          delay: this.active ? 0.1 : 0,
          ease: 'expo.out',
          y: this.active ? 0 : 50,
          opacity: this.active ? 1 : 0
        })
      })
    }
  }
}

docReady(() => {
  const qa = document.querySelectorAll('.extra-info')

  if (qa.length) {
    for (let i = 0; i < qa.length; i++) {
      // eslint-disable-next-line
      const accordionItem = new AccordionItem(qa[i])
    }
  }
})
