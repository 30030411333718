import { getMousePos } from '@/js/components/tilt'
import { TweenMax, Expo } from 'gsap'

class CustomCursor {
  constructor (el) {
    this.DOM = { el }
    this.DOM.button = this.DOM.el.querySelector('.nav-button__button')
    this.initEvents()
  }

  initEvents () {
    this.toggleAnimationOnHover = type => {
      if (this.DOM.button) {
        TweenMax.to(this.DOM.button, 1, {
          ease: Expo.easeOut,
          rotation: type === 'mouseenter' ? 0 : 45,
          scale: type === 'mouseenter' ? 1 : 0
        })
      }
    }
    /**
    * Mouseenter:
    * - Scale and rotate in
    *
    * Mousemove:
    * - follow - move all follow elements
    *
    * Mouseleave:
    * - Scale and rotate out
    */
    this.mouseenterFn = e => this.toggleAnimationOnHover(e.type)
    this.mousemoveFn = e => window.requestAnimationFrame(() => this.follow(e))
    this.mouseleaveFn = e => this.toggleAnimationOnHover(e.type)
    if (window.matchMedia('(min-width: 768px)').matches) {
      this.DOM.el.addEventListener('mouseenter', this.mouseenterFn)
      this.DOM.el.addEventListener('mousemove', this.mousemoveFn)
      this.DOM.el.addEventListener('mouseleave', this.mouseleaveFn)
    }
  }

  follow (e) {
    // Get mouse position
    const mousepos = getMousePos(e)
    // Document scrolls
    const docScrolls = {
      left: document.body.scrollLeft + document.documentElement.scrollLeft,
      top: document.body.scrollTop + document.documentElement.scrollTop
    }
    const bounds = this.DOM.el.getBoundingClientRect()
    const buttonBounds = this.DOM.button.getBoundingClientRect()
    // Mouse position relative to the main element (this.DOM.el)
    const relmousepos = {
      x: mousepos.x - bounds.left - docScrolls.left,
      y: mousepos.y - bounds.top - docScrolls.top
    }
    // Movement settings for the follow elements
    if (this.DOM.button) {
      TweenMax.to(this.DOM.button, 2, {
        ease: Expo.easeOut,
        x: relmousepos.x - (bounds.width / 2) - (buttonBounds.width / 2),
        y: relmousepos.y - (bounds.height / 2) - (buttonBounds.height / 2)
      })
    }
  }
}

jQuery(document).ready(() => {
  const navButtons = document.querySelectorAll('.nav-button')
  jQuery.each(navButtons, (index, el) => {
    // eslint-disable-next-line
    const customCursor = new CustomCursor(el)
  })
})
