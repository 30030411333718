import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

const observer = new window.IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const el = entry.target
      observer.unobserve(el)

      gsap.to(el, {
        duration: 1.3,
        ease: 'expo.out',
        opacity: 1,
        y: 0,
        onComplete: () => {
          gsap.set(el, {
            clearProps: 'all'
          })
          el.classList.add('s--complete')
        }
      })
    }
  })
})

docReady(() => {
  const anims = document.querySelectorAll('.s')
  if (anims.length) {
    anims.forEach(item => {
      gsap.set(item, {
        opacity: 0,
        y: 50
      })
      observer.observe(item)
    })
  }
})
