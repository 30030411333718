import { TweenMax, Expo } from 'gsap'

// Gets the mouse position
export const getMousePos = e => {
  let posx = 0
  let posy = 0
  if (!e) {
    e = window.event
  }
  if (e.pageX || e.pageY) {
    posx = e.pageX
    posy = e.pageY
  } else if (e.clientX || e.clientY) {
    posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
    posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop
  }
  return {
    x: posx,
    y: posy
  }
}

class Tilt {
  constructor (el) {
    this.DOM = { el }
    // The following elements will tilt and scale
    this.DOM.scale = {}
    this.scaleConfig = {}
    this.DOM.tilt = {}
    this.initEvents()
  }

  initEvents () {
    this.toggleAnimationOnHover = type => {
      // Scale up the scale elements
      for (const key in this.DOM.scale) {
        const s = this.scaleConfig[key]
        if (this.DOM.scale[key]) {
          TweenMax.to(this.DOM.scale[key], 1, {
            ease: Expo.easeOut,
            scale: type === 'mouseenter' ? s.in : s.out
          })
        }
      }
    }

    /**
    * Mouseenter:
    * - Scale up the DOM.bg element
    * - Animate the role letters
    *
    * Mousemove:
    * - tilt - move all tilt elements
    *
    * Mouseleave:
    * - Scale down the DOM.bg element
    * - Animate the role letters
    */
    this.mouseenterFn = e => this.toggleAnimationOnHover(e.type)
    this.mousemoveFn = e => window.requestAnimationFrame(() => this.tilt(e))
    this.mouseleaveFn = e => this.toggleAnimationOnHover(e.type)
    if (window.matchMedia('(min-width: 768px)').matches) {
      this.DOM.el.addEventListener('mouseenter', this.mouseenterFn)
      this.DOM.el.addEventListener('mousemove', this.mousemoveFn)
      this.DOM.el.addEventListener('mouseleave', this.mouseleaveFn)
    }
  }

  tilt (e) {
    // Get mouse position
    const mousepos = getMousePos(e)
    // Document scrolls
    const docScrolls = {
      left: document.body.scrollLeft + document.documentElement.scrollLeft,
      top: document.body.scrollTop + document.documentElement.scrollTop
    }
    const bounds = this.DOM.el.getBoundingClientRect()
    // Mouse position relative to the main element (this.DOM.el)
    const relmousepos = {
      x: mousepos.x - bounds.left - docScrolls.left,
      y: mousepos.y - bounds.top - docScrolls.top
    }
    // Movement settings for the tilt elements
    for (const key in this.DOM.tilt) {
      // Animate each of the elements
      if (this.DOM.tilt[key]) {
        TweenMax.to(this.DOM.tilt[key], 2, {
          ease: Expo.easeOut,
          x: 20 / bounds.width * relmousepos.x - 10,
          y: 20 / bounds.height * relmousepos.y - 10
        })
      }
    }
  }
}

class PartnershipTilt extends Tilt {
  constructor (el) {
    super(el)
    this.DOM.scale.item = this.DOM.el
    this.scaleConfig = {
      item: {
        in: 1.08,
        out: 1
      }
    }
    // The overlay
    this.DOM.tilt.item = this.DOM.el
  }
}

class BasicTilt extends Tilt {
  constructor (el) {
    super(el)
    this.DOM.scale.item = this.DOM.el
    this.scaleConfig = {
      item: {
        in: 1.06,
        out: 1
      }
    }
    this.DOM.tilt.item = this.DOM.el
  }
}

jQuery(document).ready(() => {
  const partnershipItems = document.querySelectorAll('.partnership-item')
  jQuery.each(partnershipItems, (index, el) => {
    // eslint-disable-next-line
    const tilt = new PartnershipTilt(el)
  })

  const basicItems = document.querySelectorAll('.l-overview .small-page, .l-overview .overview-question, .l-experiences .small-experience')
  jQuery.each(basicItems, (index, el) => {
    // eslint-disable-next-line
    const tilt = new BasicTilt(el)
  })
})
